
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { Actions } from '@/store/enums/StoreEnums';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import PartnerDetailsCard from '@/components/cards/PartnerDetailsCard.vue';
import carHeader from '@/core/data/car/partnerCarHeader';
import {
  individualLabels,
  companyLabels,
} from '@/core/data/partner/partnerLabel';
import moment from 'moment';

export default {
  name: 'PartnerDetails',
  components: {
    Datatable,
    PartnerDetailsCard,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { id } = route.params;
    const data = ref<any>([]);
    const pictureLabels = ['รูปถ่ายใบหน้า', 'บัตรประชาชน', 'สำเนาบุ๊คแบงค์'];
    const carData = ref([]);
    const documentHeader = [
      { name: 'Image', key: 'image', sortable: false },
      { name: 'Description', key: 'title', sortable: true },
    ];
    const currentImage = ref('');
    const labels = ref<any>([]);
    const documents = ref<any>([]);
    const timeFormat = ['birthdate'];

    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

    onMounted(async () => {
      const { data: partnerData } = await ApiService.get(`/partner/${id}`);

      data.value = partnerData.data;

      document.title = data.value.name || 'Partner Details';

      const roles: Array<string> = [];
      const states: Array<string> = [];

      data.value.staff.verifyState === 'Done' && roles.push('Staff');
      data.value.driver.verifyState === 'Done' && roles.push('Driver');
      data.value.organization.verifyState === 'Done' &&
        roles.push(data.value.organization.type);

      data.value.role = roles.join(', ');

      states.push(data.value.staff.verifyState);
      states.push(data.value.driver.verifyState);
      states.push(data.value.organization.verifyState);

      if (
        states.every((state) => state === 'Done' || state === 'PrepareVerify')
      ) {
        data.value.verifyState = 'Done';
      }

      roles.forEach((role) => {
        const key =
          role === 'Company' || role === 'Individual'
            ? 'organization'
            : role.toLowerCase();
        documents.value.push({
          name: role,
          data: data.value[key].verifyDocuments,
        });
      });

      individualLabels.forEach((label) => {
        if (label.key === '') {
          label.key = roles.includes('Driver') ? 'driver' : 'staff';
        }
      });

      if (data.value.organization.verifyState !== 'PrepareVerify') {
        labels.value = [...individualLabels, ...companyLabels];
      } else {
        labels.value = [...individualLabels];
      }

      setCurrentPageBreadcrumbs(
        'Partner Account',
        [
          { name: 'Account Lists', path: '/partner-account/lists' },
          'Account Details',
        ],
        {}
      );

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
    });

    const changeImage = (image) => {
      currentImage.value = image;
    };

    return {
      labels,
      data,
      pictureLabels,
      carHeader,
      carData,
      documentHeader,
      currentImage,
      changeImage,
      documents,
      timeFormat,
      moment,
    };
  },
};
