const individualLabels = [
  {
    name: 'ชื่อ / Name',
    key: 'name',
  },
  {
    name: 'เพศ / Gender',
    key: 'gender',
  },
  {
    name: 'วัดเกิด / Dath of Birth',
    key: 'birthdate',
  },
  {
    name: 'บทบาท / Role',
    key: 'role',
  },
  {
    name: 'หมายเลขบัตรประชาชน / National ID',
    key: '',
    key2: 'personalId',
  },
  {
    name: 'ที่อยู่ / Address',
    key: '',
    key2: 'address',
  },
  {
    name: 'จังหวัด / Province',
    key: '',
    key2: 'province',
    key3: 'name',
  },
  {
    name: 'อำเภอ / District',
    key: '',
    key2: 'district',
    key3: 'name',
  },
  {
    name: 'ตำบล / Sub-district',
    key: '',
    key2: 'subDistrict',
    key3: 'name',
  },
  {
    name: 'รหัสไปรษณีย์ / Postal Code',
    key: '',
    key2: 'postNumber',
  },
  {
    name: 'เบอร์โทรศัพท์ / Phone Number',
    key: 'phone',
  },
  {
    name: 'อีเมล / Email',
    key: 'email',
  },
  {
    name: 'บัญชีธนาคาร / Bank Account',
    key: '',
    key2: 'personalBackId',
  },
  {
    name: 'หมายเลขบัญชีธนาคาร / Bank Account Number',
    key: '',
    key2: 'bankAccountNo',
  },
];

const companyLabels = [
  {
    name: 'ที่อยู่บริษัท / Company Address',
    key: 'organization',
    key2: 'address',
  },
  {
    name: 'จังหวัดบริษัท / Company Province',
    key: 'organization',
    key2: 'province',
    key3: 'name',
  },
  {
    name: 'อำเภอบริษัท / Company District',
    key: 'organization',
    key2: 'district',
    key3: 'name',
  },
  {
    name: 'ตำบลบริษัท / Company Sub-district',
    key: 'organization',
    key2: 'subDistrict',
    key3: 'name',
  },
  {
    name: 'รหัสไปรษณีย์บริษัท / Company Postal Code',
    key: 'organization',
    key2: 'postNumber',
  },
  {
    name: 'เบอร์โทรศัพท์บริษัท / Company Phone Number',
    key: 'organization',
    key2: 'phone',
  },
  {
    name: 'อีเมลบริษัท / Company Email',
    key: 'organization',
    key2: 'email',
  },
  {
    name: 'หมายเลขบัญชีธนาคารบริษัท / Company Bank Account Number',
    key: 'organization',
    key2: 'bankAccountNo',
  },
];

export { individualLabels, companyLabels };
